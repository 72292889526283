import Container from 'components/elements/container';
import { Title, Text } from './page-confid.styled';

function PageConfid() {
  return (
    <Container>
      <Title>Политика конфиденциальности сервиса «BSTR»</Title>
      <Text>
        <h2>1.         ОБЩИЕ ПОЛОЖЕНИЯ</h2>

        <p>
          1.1.      Настоящая политика конфиденциальности (далее – « Политика ») описывает принципы и порядок обработки
          персональных данных ООО « Интеллектуальная Транспортная Инфраструктура» (ООО «ИТИ») (далее – « Общество »),
          расположенным по адресу 191123, г. Санкт-Петербург, ул. Радищева, д. 39, литера Д, часть помещения 10-Н, ком.
          58 , а также реализованные Обществом меры защиты персональных данных в связи с администрированием электронного
          сервиса « BSTR ». Настоящая Политика размещается в магазинах приложений, посредством которых можно скачать
          приложение « BSTR », а также в самом приложении и обязательна к ознакомлению всем субъектам персональных
          данных, предоставляющим свои данные Обществу посредством приложения « BSTR ».
        </p>

        <p>1.2.      В настоящей Политике используются следующие термины:</p>

        <p>(1)       « Пользователи » – физические лица, использующие Приложение.</p>

        <p>
          (2)       «Поставщики платежных услуг» – партнеры Общества, вместе с кредитными (расчетными) организациями
          Пользователей обеспечивающие расчетно-кассовое обслуживание при осуществлении расчетов по приобретаемым
          Пользователями товарам, работам и услугам Поставщиков транспортных услуг.
        </p>

        <p>
          (3)       «Поставщики транспортных услуг» – партнеры Общества, предоставляющие услуги по парковке, стоянке и
          мойке автомобилей, а также реализующие иные товары, работы и услуги в сфере транспортного обслуживания на
          самостоятельно определяемых ими условиях с использованием Приложения.
        </p>

        <p>
          (4)       « Приложение » – мобильное приложение « BSTR », владельцем которого является Общество, доступное для
          скачивания в магазинах приложений App Store и Google Play . 
        </p>

        <p>
          1.3.      Термины, не определенные в настоящей Политике, имеют значение, которое придается им
          законодательством Российской Федерации (в первую очередь, Федеральным законом № 152-ФЗ от 27.07.2006 «О
          персональных данных»).
        </p>

        <h2>2.         ПРИНЦИПЫ ОБРАБОТКИ ПЕРСОНАЛЬНЫХ ДАННЫХ</h2>

        <p>2.1.      Обработка персональных данных осуществляется Обществом на основе принципов:</p>

        <p>(1)       законности целей и способов обработки персональных данных;</p>

        <p>
          (2)       соответствия целей обработки персональных данных целям, заранее определенным и заявленным при сборе
          персональных данных;
        </p>

        <p>
          (3)       соответствия объема и характера обрабатываемых персональных данных, способов их обработки целям
          обработки персональных данных;
        </p>

        <p>
          (4)       точности и актуальности персональных данных, их достаточности для целей обработки, недопустимости
          обработки персональных данных, избыточных по отношению к целям, заявленным при сборе персональных данных;
        </p>

        <p>
          (5)       недопустимости объединения созданных для несовместимых между собой целей баз данных, содержащих
          персональные данные.
        </p>

        <p>
          2.2.      В отношении персональных данных введен режим конфиденциальности. Обработка персональных данных
          лицами, не допущенными к их обработке в установленном порядке, не допускается.
        </p>

        <h2>3.         ОСНОВАНИЯ ОБРАБОТКИ ПЕРСОНАЛЬНЫХ ДАННЫХ</h2>

        <p>
          3.1.      Правовыми основаниями обработки персональных данных Обществом являются Налоговый кодекс Российской
          Федерации, Гражданский кодекс Российской Федерации, Федеральный закон от 06.12.2011 № 402-ФЗ «О бухгалтерском
          учете», Приказ Росархива от 20.12.2019 № 236 «Об утверждении Перечня типовых управленческих архивных
          документов, образующихся в процессе деятельности государственных органов, органов местного самоуправления и
          организаций, с указанием сроков их хранения», другие нормативные правовые акты, регулирующие деятельность
          Общества, а также его учредительные документы.
        </p>

        <p>
          3.2.      Если обработка персональных данных не предусмотрена законодательными актами Российской Федерации,
          Общество осуществляет обработку персональных данных только в следующих случаях:
        </p>

        <p>
          (1)       Обществом или иным лицом получено согласие субъекта персональных данных на обработку его
          персональных данных Обществом;
        </p>

        <p>
          (2)       обработка персональных данных необходима для исполнения договора, стороной которого является субъект
          персональных данных;
        </p>

        <p>
          (3)       обработка персональных данных необходима для заключения договора по инициативе субъекта персональных
          данных или договора, по которому субъект персональных данных будет являться выгодоприобретателем или
          поручителем;
        </p>

        <p>
          (4)       обработка персональных данных необходима для осуществления прав и законных интересов Общества или
          третьих лиц.
        </p>

        <h2>4.         СОСТАВ ОБРАБАТЫВАЕМЫХ ПЕРСОНАЛЬНЫХ ДАННЫХ</h2>

        <p>
          4.1.      Общество осуществляет обработку только тех персональных данных, которые были предоставлены Обществу
          субъектами персональных данных или были предоставлены Обществу третьими лицами с согласия субъектов
          персональных данных.
        </p>

        <p>
          4.2.      При использовании Приложения Пользователь может предоставить Обществу (Обществом может получать)
          следующие персональные данные Пользователя:
        </p>

        <p>(1)       идентификаторы Пользователя в магазине приложений;</p>

        <p>(2)       данные геолокации Пользователя;</p>

        <p>(3)       история взаимодействия с Поставщиками транспортных услуг;</p>

        <p>(4)       адрес электронной почты;</p>

        <p>(5)       данные об используемых Пользователем транспортных средствах;</p>

        <p>(6)        персональные настройки Пользователя в Приложении и данные о его использовании.</p>

        <p>
          4.3.      Общество не осуществляет обработку биометрических персональных данных, а также специальных категорий
          персональных данных.{' '}
        </p>

        <h2>5.         ЦЕЛИ И СПОСОБЫ ОБРАБОТКИ ПЕРСОНАЛЬНЫХ ДАННЫХ</h2>

        <p>5.1.      Персональные данные Пользователей обрабатываются Обществом для достижения следующих целей:</p>

        <p>(1)       заключение и исполнение договоров с Пользователями;</p>

        <p>(2)       обеспечение функционирования Приложения на согласованных с Пользователями условиях;</p>

        <p>
          (3)       организация взаимодействия между Пользователями, Поставщиками транспортных услуг и Поставщиками
          платежных услуг;
        </p>

        <p>(4)       рассмотрение обращений Пользователей;</p>

        <p>(5)       направление Пользователям информации об услугах, оказываемых Обществом и его партнерами;</p>

        <p>(6)       оценка эффективности маркетинговых кампаний;</p>

        <p>
          (7)       анализ статистики использования Приложения в целях совершенствования сервисов, предоставляемых
          Обществом;
        </p>

        <p>
          (8)       обеспечение текущей хозяйственной деятельности Общества (включая юридическое сопровождение,
          бухгалтерский, налоговый и управленческий учет, а также обслуживание информационной инфраструктуры);
        </p>

        <p>
          (9)       осуществление прав и законных интересов Общества или третьих лиц (включая разрешение споров и иных
          конфликтных ситуаций);
        </p>

        <p>(10)     исполнение требований законодательства Российской Федерации.</p>

        <p>
          5.2.      Поскольку указанные операции соответствуют заявленным выше целям, персональные данные могут
          обрабатываться Обществом как с использованием средств автоматизации, так и без их применения посредством их
          сбора, систематизации, накопления, хранения, уточнения, использования, блокирования, передачи и уничтожения.
        </p>

        <h2>6.         ХРАНЕНИЕ И ЗАЩИТА ПЕРСОНАЛЬНЫХ ДАННЫХ</h2>

        <p>
          6.1.      Обществом обеспечивается контроль доступа в помещения, используемые для обработки персональных
          данных. В случае наличия в помещении документов, хранение которых осуществляется вне запираемых шкафов
          (сейфов, ящиков) лица, не имеющие права доступа к хранящимся в них носителям персональных данных, имеют право
          доступа в указанные помещения только в присутствии уполномоченных сотрудников, которые осуществляют контроль
          соблюдения такими лицами ограничений доступа к персональным данным.{' '}
        </p>

        <p>
          6.2.      При обработке персональных данных в электронном виде реализуются организационные, правовые и
          технические меры защиты, исключающие возможность несанкционированного доступа к персональным данным со стороны
          лиц, не допущенных к их обработке. Среди прочего, указанные меры включают:
        </p>

        <p>(1)       определение актуальных угроз безопасности персональных данных;</p>

        <p>
          (2)       обеспечение режима безопасности помещений, в которых размещены информационные системы персональных
          данных, препятствующего возможности неконтролируемого пребывания в этих помещениях лиц, не имеющих права
          доступа в них;
        </p>

        <p>
          (3)       определение перечня лиц, доступ которых к персональным данным необходим для выполнения ими служебных
          обязанностей;
        </p>

        <p>
          (4)       обеспечение сохранности носителей персональных данных (в том числе, учет съемных машинных носителей
          персональных данных);
        </p>

        <p>(5)       управление доступом к персональным данным (включая применение мер парольной защиты);</p>

        <p>(6)       контроль защищенности персональных данных;</p>

        <p>
          (7)       обеспечение доступности персональных данных (в том числе, посредством резервного копирования
          персональных данных с установленной периодичностью);
        </p>

        <p>(8)       антивирусную защиту информационных систем персональных данных.</p>

        <h2>7.         ПЕРЕДАЧА ПЕРСОНАЛЬНЫХ ДАННЫХ</h2>

        <p>
          7.1.      Персональные данные Пользователей могут передаваться третьим лицам в тех случаях, когда такая
          передача вытекает из логики и цели использования Приложения, в частности, для организации взаимодействия
          Пользователей, Поставщиков транспортных услуг и Поставщиков платежных услуг.
        </p>

        <p>
          7.2.      Персональные данные любых категорий субъектов персональных данных могут быть переданы провайдерам
          услуг информационно-технологического обслуживания, включая провайдеров услуг хостинга, операторов
          информационных систем, провайдеров услуг технической поддержки.{' '}
        </p>

        <p>
          7.3.      Полный перечень лиц, обрабатывающих персональные данные субъекта по поручению Общества, может быть
          предоставлен субъекту в порядке, предусмотренном разделом 9 настоящей Политики.
        </p>

        <p>
          7.4.      При передаче персональных данных третьим лицам Общество уведомляет указанных лиц об обязанности
          сохранения конфиденциальности персональных данных и использования их лишь в тех целях, для которых они
          переданы.
        </p>

        <p>
          7.5.      При передаче персональных данных лицам, обрабатывающим эти данные по поручению (в интересах)
          Общества, в соглашении с таким лицом предусматриваются:
        </p>

        <p>
          (1)       перечень действий (операций) с персональными данными, которые будут совершаться лицом,
          осуществляющим обработку персональных данных;
        </p>

        <p>(2)       допустимые цели обработки данных таким лицом;</p>

        <p>
          (3)       обязанность такого лица соблюдать конфиденциальность персональных данных и обеспечивать безопасность
          персональных данных при их обработке;
        </p>

        <p>(4)       конкретные требования к защите обрабатываемых персональных данных.</p>

        <p>
          7.6.      Предоставление персональных данных государственным органам производится в соответствии с
          требованиями действующего законодательства Российской Федерации.
        </p>

        <h2>8.         СРОКИ ОБРАБОТКИ ПЕРСОНАЛЬНЫХ ДАННЫХ</h2>

        <p>
          8.1.      Обработка персональных данных Пользователей для обеспечения функционирования Приложения, анализа
          статистики использования Приложения в целях совершенствования предоставляемых Обществом сервисов, а также для
          направления Пользователям информации об услугах, оказываемых Обществом и его партнерами, осуществляется до
          прекращения деятельности Общества как юридического лица или до прекращения Обществом поддержки Приложения.
          Сроки обработки персональных данных в иных случаях определяются целями обработки персональных данных и/или
          отдельными согласиями субъектов персональных данных.
        </p>

        <p>
          8.2.      Обработка персональных данных (в том числе, их хранение) в любом случае подлежит прекращению по
          достижении целей обработки соответствующих данных, а также в случае отпадения оснований для обработки
          персональных данных (в том числе, в случае отзыва ранее предоставленного согласия на обработку персональных
          данных, если законодательство не предоставляет Обществу право продолжить обработку персональных данных).
        </p>

        <h2>9.         ПРАВА СУБЪЕКТОВ ПЕРСОНАЛЬНЫХ ДАННЫХ</h2>

        <p>9.1.      Субъекты персональных данных вправе:</p>

        <p>(1)       получать доступ к информации, касающейся обработки их персональных данных;</p>

        <p>
          (2)       требовать от Общества уточнения персональных данных, их блокирования или уничтожения в случае, если
          персональные данные являются неполными, устаревшими, неточными, незаконно полученными или не являются
          необходимыми для заявленной цели обработки;
        </p>

        <p>
          (3)       обжаловать в суд любые неправомерные действия или бездействия Общества при обработке и защите
          персональных данных, а также принимать иные предусмотренные законом меры по защите своих прав.
        </p>

        <p>
          9.2.      Субъект персональных данных имеет право на получение информации, касающейся обработки его
          персональных данных, содержащей:
        </p>

        <p>(1)       подтверждение факта обработки персональных данных Обществом;</p>

        <p>(2)       правовые основания и цели обработки персональных данных;</p>

        <p>(3)       применяемые Обществом способы обработки персональных данных;</p>

        <p>
          (4)       наименование и место нахождения Общества, сведения о лицах, которые имеют доступ к персональным
          данным или которым могут быть раскрыты персональные данные на основании договора с Обществом или на основании
          федерального закона;
        </p>

        <p>
          (5)       обрабатываемые персональные данные, относящиеся к соответствующему субъекту персональных данных,
          источник их получения, если иной порядок представления таких данных не предусмотрен федеральным законом;
        </p>

        <p>(6)       сроки обработки персональных данных, в том числе сроки их хранения;</p>

        <p>
          (7)       порядок осуществления субъектом персональных данных прав, предусмотренных Законом о персональных
          данных;
        </p>

        <p>(8)       информацию о трансграничной передаче данных;</p>

        <p>(9)       сведения о месте нахождения базы данных информации, содержащей персональные данные;</p>

        <p>
          (10)     наименование или фамилию, имя, отчество и адрес лица, осуществляющего обработку персональных данных
          по поручению Общества, если обработка поручена или будет поручена такому лицу;
        </p>

        <p>(11)     иные сведения, предусмотренные Законом о персональных данных или другими федеральными законами.</p>

        <p>
          9.3.      Сведения, указанные в пункте 9.2, предоставляются субъекту персональных данных или его представителю
          при обращении либо при получении запроса субъекта персональных данных или его представителя. Запрос должен
          содержать номер основного документа, удостоверяющего личность субъекта персональных данных или его
          представителя, сведения о дате выдачи указанного документа и выдавшем его органе, сведения, подтверждающие
          участие субъекта персональных данных в отношениях с Обществом, либо сведения, иным образом подтверждающие факт
          обработки персональных данных Обществом. Кроме того, запрос должен быть подписан субъектом персональных данных
          или его представителем.
        </p>

        <p>
          9.4.      Если лицо, обратившееся в Общество с обращением или запросом, не уполномочено на получение
          информации, относящейся к персональным данным, соответствующему лицу отказывается в выдаче такой информации.
          Лицу, обратившемуся с соответствующим запросом, выдается уведомление об отказе в выдаче информации.
        </p>

        <p>
          9.5.      Ответы на обращения и запросы о предоставлении сведений, указанных в пункте 9.2, направляются в
          течение 30 (тридцати) дней с момента их поступления в Общество.
        </p>

        <p>
          9.6.      В случае получения обращения, содержащего информацию об обработке Обществом неточных персональных
          данных или неправомерной обработке персональных данных, ответственное за рассмотрение запроса лицо
          незамедлительно организует блокирование таких персональных данных на период проверки. В случае сообщения об
          обработке Обществом неточных персональных данных блокирование осуществляется при условии, что оно не нарушает
          права и законные интересы субъекта персональных данных или третьих лиц.
        </p>

        <p>
          9.7.      В случае подтверждения факта неточности обрабатываемых персональных данных на основании сведений,
          представленных субъектом персональных данных, его представителем или уполномоченным органом по защите прав
          субъектов персональных данных, обеспечивается уточнение персональных данных в течение 7 (семи) рабочих дней со
          дня представления таких сведений. Если уточнение данных невозможно осуществить в указанный срок, уточнение
          осуществляется в кратчайшие возможные сроки. Разблокирование данных производится по итогам их уточнения.
        </p>

        <p>
          9.8.      В случае выявления по итогам проверки неправомерной обработки персональных данных Обществом,
          производится устранение нарушения в срок, не превышающий 3 (три) рабочих дня с момента подтверждения факта
          неправомерной обработки.{' '}
        </p>

        <p>
          9.9.      Если обеспечить правомерность обработки персональных данных невозможно, в срок, не превышающий 10
          (десять) рабочих дней со дня выявления неправомерной обработки персональных данных, производится уничтожение
          данных.{' '}
        </p>

        <p>
          9.10.    Об устранении допущенных нарушений или об уничтожении персональных данных немедленно уведомляется
          субъект персональных данных или его представитель, а если обращение либо запрос были направлены уполномоченным
          органом по защите прав субъектов персональных данных – также указанный орган.
        </p>

        <p>
          9.11.    В случае отзыва субъектом персональных данных согласия на обработку его данных сотрудники Общества
          обязаны прекратить обработку персональных данных и уничтожить их в течение 30 (тридцати) дней. Требования
          настоящего пункта не подлежат применению, если иное предусмотрено договором, стороной которого является
          субъект персональных данных, или действующим законодательством.
        </p>
      </Text>
    </Container>
  );
}

export default PageConfid;
