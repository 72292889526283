import Container from 'components/elements/container';
import Block2View from './block2view';
import './block2.css';

function Block2() {
  const items = [
    { icon: '/images/block2/icon1.svg', title: 'Без регистрации' },
    { icon: '/images/block2/icon2.svg', title: 'Напоминания об окончании парковки' },
    { icon: '/images/block2/icon3.svg', title: 'История оплат' },
    { icon: '/images/block2/icon4.svg', title: 'Добавление нескольких машин' },
  ];
  return (
    <div className="block block2" id="avd">
      <Container>
        <Block2View
          title="Оплачивайте <br />
          городские парковки, <br />
          <b>не выходя из машины</b>"
          desc="Больше не нужно искать свободные места, паркоматы, запоминать время парковки - с помощью BSTR оплата и
          продление парковки происходит не выходя из машины. Приложение запомнит предыдущие сессии, напомнит об
          окончании парковки и сделает поездку в центр города комфортнее."
          items={items}
        />
      </Container>
    </div>
  );
}

export default Block2;
