import { useState } from 'react';

export interface IFaqItem {
  title: string;
  answer: string;
}

function BlockFaqItem(props: IFaqItem) {
  const { title, answer } = props;
  const [open, setOpen] = useState(false);
  const cl = open ? ' active' : '';

  return (
    <div className={'blockfaq__item' + cl} onClick={() => setOpen(!open)}>
      <span className="question">
        <span>{title}</span>
        <span className="arrow" />
      </span>
      <div className="answer">{answer}</div>
    </div>
  );
}

export default BlockFaqItem;
