import { useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';

import { init } from 'common';
import Layout from 'components/layout';
import PageMain from 'pages/main/page-main';
import PageConfid from 'pages/confid/page-confid';

function App() {
  useEffect(() => {
    init();
  }, []);

  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route index element={<PageMain />} />
        <Route path="confid" element={<PageConfid />} />
        <Route path="*" element={<PageMain />} />
      </Route>
    </Routes>
  );
}

export default App;
