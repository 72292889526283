import Container from 'components/elements/container';
import Block1View from './block1view';
import './block1.css';

function Block1() {
  const items = [
    { w: 28, title: 'Городские парковки', icon: '/images/block1/icon_parkingmini.svg' },
    { w: 24, title: 'Мойки', icon: '/images/block1/icon_washmini.svg' },
    { w: 30, title: '“Подорожник”', icon: '/images/block1/icon_cardmini.svg' },
    { w: 30, title: 'Билет на транспорт', icon: '/images/block1/icon_ticketmini.svg' },
  ];

  return (
    <div className="block block1 two-columns" id="about">
      <Container>
        <Block1View
          title="Транспортное приложение"
          desc="Мобильная оплата в пару кликов."
          img="img1.jpg"
          items={items}
        />
      </Container>
    </div>
  );
}

export default Block1;
