import Container from 'components/elements/container';
import Block3View from './block3view';
import './block3.css';

function Block3() {
  const items = [
    { icon: '/images/block3/img1.svg', title: 'Выберите место для парковки' },
    { icon: '/images/block3/img2.svg', title: 'Укажите время' },
    { icon: '/images/block3/img3.svg', title: 'Оплатите парковку банковской картой' },
    { icon: '/images/block3/img4.svg', title: 'Добавьте время, если необходимо' },
  ];

  return (
    <div className="block block3" id="hiw">
      <div className="title">Как это работает?</div>
      <div className="block3__back">
        <Container>
          <Block3View items={items} />
        </Container>
      </div>
    </div>
  );
}

export default Block3;
