import config from 'config';
import BlockFaqItem, { IFaqItem } from './blockfaq-item';
import Container from 'components/elements/container';
import './blockfaq.css';

function BlockFaq() {
  const items = config.faq.map((el: IFaqItem, idx: any) => {
    return <BlockFaqItem {...el} key={idx} />;
  });

  return (
    <div className="block blockfaq" id="faq">
      <Container>
        <div className="title">Частые вопросы</div>
        {items}
      </Container>
    </div>
  );
}

export default BlockFaq;
